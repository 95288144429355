<template>
  <div>
    <TenderCard :tender="tender" />
    <v-toolbar-title class="primary--text text--darken-1 ml-4">
      Tender Clarification
    </v-toolbar-title>
    <v-row v-if="!user.is_admin">
      <v-col cols="12" sm="10" class="ml-1 mb-n5">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-textarea
            v-model="item.question"
            auto-grow
            class="mx-2"
            label="Type here your clarification"
            rows="1"
            append-icon="mdi-comment"
            color="primary"
            outlined
            dense
            :rules="requiredRules"
          ></v-textarea>
        </v-form>
      </v-col>
      <v-col cols="12" sm="1" class="mt-">
        <v-btn color="primary" dark @click="postQuestion" :loading="loading"
          >Submit</v-btn
        >
      </v-col>
    </v-row>
    <v-row >
      <v-card class="ml-6">
        <v-expansion-panels v-model="panel" :readonly="readonly" multiple>
          <v-expansion-panel
            v-for="(item, i) in tender.clarifications"
            :key="i"
          >
            <v-expansion-panel-header
              ><h4 class="error--text text--darken-1">
                Clarification {{ item.id }}
              </h4></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              Question: {{ item.question }}
              <v-divider></v-divider>
              <div
                class="mt-3 font-weight-bold success--text"
                v-if="!user.is_admin"
              >
                Answer: {{ item.answer }}
              </div>
              <div class="mt-3 font-weight-bold success--text" v-else>
                <v-row>
                  <v-col cols="12" sm="10" class="ml-1 mt-5 mb-n5">
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <v-textarea
                        v-model="item.answer"
                        auto-grow
                        class="mx-2"
                        label="Type here your clarification"
                        rows="1"
                        append-icon="mdi-comment"
                        color="primary"
                        outlined
                        :rules="requiredRules"
                      ></v-textarea>
                    </v-form>
                  </v-col>
                  <v-col cols="12" sm="1" class="mt-7">
                    <v-btn
                      color="primary"
                      dark
                      @click="postAnswer(item.answer)"
                      :loading="loading"
                      >Submit</v-btn
                    >
                  </v-col>
                </v-row>


              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import TenderCard from "@/components/TenderCard.vue";
import TenderService from "../../services/tender.service";

export default {
  components: {
    TenderCard,
  },
  props: {
    id: {},
  },
  data: () => ({
    tender: {},
    panel: [0, 1, 2, 3, 4, 5, 6],
    readonly: false,
    name: "TenderClarifications",
    docURI:
      "https://portal.ufaa.go.ke/frontend/web/index.php/page/download-all?tenderID=37",
    item: {},
    loading: false,
    requiredRules: [
      (v) => !!v || "Field is required",
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    valid: true,
    // [
    //   {
    //     question:
    //       "Could you please confirm that CEDEFOP will accept Tenders with postmark on the 5th of December 2006, irrespectively from the exact time submitted to the post office? ",
    //     answer: "Yes. ",
    //     date: "2020-11-17 14:30:00",
    //   },
    //   {
    //     question:
    //       "Cover Letter, point 4, page 2, “Tenderers must ensure that their tenders are signed by a duly authorised representative…”. And Annex F – “both the technical and financial proposal of the offer are signed by the tenderer or his duly authorised agent. Question: Could you please confirm that a signed cover letter in our offer by the Tenderer’s duly authorised representative is sufficient? If not, please specify whether it is requested by CEDEFOP to sign each page of both the technical and the financial proposal. ",
    //     answer:
    //       "Signature in the cover letter, as well as a signature in the financial offer, are enough. ",
    //     date: "2020-11-17 14:30:00",
    //   },
    //   {
    //     question:
    //       "Could you please confirm that the deadline for raising questions is the 29th of November 2006? ",
    //     answer:
    //       "As mentioned in the tendering documents: “Request for additional information received less than five working days before the closing date for submission of tenders will not be processed”. ",
    //     date: "2020-11-17 14:30:00",
    //   },
    //   {
    //     question:
    //       "Could you please confirm that the deadline for raising questions is the 29th of November 2006? ",
    //     answer:
    //       "As mentioned in the tendering documents: “Request for additional information received less than five working days before the closing date for submission of tenders will not be processed”. ",
    //     date: "2020-11-17 14:30:00",
    //   },
    //   {
    //     question:
    //       "Could you please confirm that the deadline for raising questions is the 29th of November 2006? ",
    //     answer:
    //       "As mentioned in the tendering documents: “Request for additional information received less than five working days before the closing date for submission of tenders will not be processed”. ",
    //     date: "2020-11-17 14:30:00",
    //   },
    //   {
    //     question:
    //       "Could you please confirm that the deadline for raising questions is the 29th of November 2006? ",
    //     answer:
    //       "As mentioned in the tendering documents: “Request for additional information received less than five working days before the closing date for submission of tenders will not be processed”. ",
    //     date: "2020-11-17 14:30:00",
    //   },
    // ],
  }),
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },
  created() {
    this.getTender();
  },
  methods: {
    postQuestion() {
      this.$refs.form.validate();
      if (!this.item.question || this.item.question.length < 3) {
        return false;
      }
      this.loading = true;
      this.item["tender_id"] = this.tender.id;
      return TenderService.storeClarification(this.item).then(
        (response) => {
          if (response.status == 200) {
            this.tender = response.data.data;
            this.$store.dispatch(
              "alert/success",
              response.data.message || response.statusText
            );
            this.getTender();
            this.loading = false;
          } else {
            this.loading = false;
            this.$store.dispatch(
              "alert/error",
              response.response.data.message +
                ":" +
                JSON.stringify(response.response.data.errors) ||
                response.response.statusText
            );
            console.log(response.statusText);
          }
        },
        (error) => {
          console.log(error);
          this.$store.dispatch("alert/error", error.statusText || error);
          this.loading = false;
        }
      );
    },
    postAnswer(answer){
      // this.$refs.form.validate();
      // if (!this.item.question || this.item.question.length < 3) {
      //   return false;
      // }
      this.loading = true;
      this.item["tender_id"] = this.tender.id;
      this.item["answer"] = answer;
      return TenderService.updateClarification(this.item).then(
        (response) => {
          console.log(response);
          if (response.status == 200) {
            this.tender = response.data.data;
            this.$store.dispatch(
              "alert/success",
              response.data.message || response.statusText
            );
            this.getTender();
            this.loading = false;
          } else {
            this.loading = false;
            this.$store.dispatch(
              "alert/error",
              response.response.data.message +
                ":" +
                JSON.stringify(response.response.data.errors) ||
                response.response.statusText
            );
            console.log(response.statusText);
          }
        },
        (error) => {
          console.log(error);
          this.$store.dispatch("alert/error", error.statusText || error);
          this.loading = false;
        }
      );
    },

    getTender() {
      return TenderService.showTender(this.id).then(
        (response) => {
          if (response.status == 200) {
            this.tender = response.data.data;
            this.loading = false;
          } else {
            this.tender = [];
            this.loading = false;
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },
  },
};
</script>